
  
  .stars {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 120%;
    transform: rotate(-45deg);
    pointer-events: none; /* Permet aux clics de passer à travers */
  }
  
  .star {
    --star-color: var(--primary-color);
    --star-tail-length: 6em;
    --star-tail-height: 2px;
    --star-width: calc(var(--star-tail-length) / 6);
    --fall-duration: 9s;
    --tail-fade-duration: var(--fall-duration);
    position: absolute;
    top: var(--top-offset);
    left: 0;
    width: var(--star-tail-length);
    height: var(--star-tail-height);
    color: var(--star-color);
    background: linear-gradient(45deg, currentColor, transparent);
    border-radius: 50%;
    filter: drop-shadow(0 0 6px currentColor);
    transform: translate3d(104em, 0, 0);
    animation: fall var(--fall-duration) var(--fall-delay) linear infinite, tail-fade var(--tail-fade-duration) var(--fall-delay) ease-out infinite;
  }
  @media screen and (max-width: 750px) {
    .star {
      animation: fall var(--fall-duration) var(--fall-delay) linear infinite;
    }
  }
  .star:nth-child(1) {
    --star-tail-length: 7.24em;
    --top-offset: 47.24vh;
    --fall-duration: 11.699s;
    --fall-delay: 4.002s;
  }
  .star:nth-child(2) {
    --star-tail-length: 6.13em;
    --top-offset: 96.85vh;
    --fall-duration: 9.658s;
    --fall-delay: 0.737s;
  }
  .star:nth-child(3) {
    --star-tail-length: 6.57em;
    --top-offset: 79.81vh;
    --fall-duration: 8.746s;
    --fall-delay: 3.7s;
  }
  .star:nth-child(4) {
    --star-tail-length: 6.25em;
    --top-offset: 90.75vh;
    --fall-duration: 6.465s;
    --fall-delay: 0.039s;
  }
  .star:nth-child(5) {
    --star-tail-length: 5.71em;
    --top-offset: 63.73vh;
    --fall-duration: 11.312s;
    --fall-delay: 3.914s;
  }
  .star:nth-child(6) {
    --star-tail-length: 7.43em;
    --top-offset: 37.34vh;
    --fall-duration: 11.112s;
    --fall-delay: 5.351s;
  }
  .star:nth-child(7) {
    --star-tail-length: 7.47em;
    --top-offset: 71.16vh;
    --fall-duration: 10.978s;
    --fall-delay: 8.028s;
  }
  .star:nth-child(8) {
    --star-tail-length: 6.14em;
    --top-offset: 34.68vh;
    --fall-duration: 7.822s;
    --fall-delay: 3.378s;
  }
  .star:nth-child(9) {
    --star-tail-length: 5.11em;
    --top-offset: 10.4vh;
    --fall-duration: 6.807s;
    --fall-delay: 1.014s;
  }
  .star:nth-child(10) {
    --star-tail-length: 7.32em;
    --top-offset: 95.44vh;
    --fall-duration: 8.051s;
    --fall-delay: 6.753s;
  }
  .star:nth-child(11) {
    --star-tail-length: 6.8em;
    --top-offset: 27.25vh;
    --fall-duration: 7.815s;
    --fall-delay: 7.836s;
  }
  .star:nth-child(12) {
    --star-tail-length: 5.27em;
    --top-offset: 75.85vh;
    --fall-duration: 11.87s;
    --fall-delay: 5.929s;
  }
  .star:nth-child(13) {
    --star-tail-length: 6.2em;
    --top-offset: 49.33vh;
    --fall-duration: 11.117s;
    --fall-delay: 8.535s;
  }
  .star:nth-child(14) {
    --star-tail-length: 5.55em;
    --top-offset: 31.35vh;
    --fall-duration: 9.028s;
    --fall-delay: 5.698s;
  }
  .star:nth-child(15) {
    --star-tail-length: 6.97em;
    --top-offset: 41.8vh;
    --fall-duration: 7.35s;
    --fall-delay: 5.188s;
  }
  .star:nth-child(16) {
    --star-tail-length: 5.85em;
    --top-offset: 41.12vh;
    --fall-duration: 7.464s;
    --fall-delay: 1.076s;
  }
  .star:nth-child(17) {
    --star-tail-length: 7.41em;
    --top-offset: 79.08vh;
    --fall-duration: 6.071s;
    --fall-delay: 9.104s;
  }
  .star:nth-child(18) {
    --star-tail-length: 7.48em;
    --top-offset: 4.13vh;
    --fall-duration: 7.21s;
    --fall-delay: 0.062s;
  }
  .star:nth-child(19) {
    --star-tail-length: 6.39em;
    --top-offset: 26.13vh;
    --fall-duration: 6.654s;
    --fall-delay: 7.202s;
  }
  .star:nth-child(20) {
    --star-tail-length: 6.93em;
    --top-offset: 10.86vh;
    --fall-duration: 8.029s;
    --fall-delay: 7.665s;
  }
  .star:nth-child(21) {
    --star-tail-length: 5.3em;
    --top-offset: 61.23vh;
    --fall-duration: 6.173s;
    --fall-delay: 7.004s;
  }
  .star:nth-child(22) {
    --star-tail-length: 6.95em;
    --top-offset: 71.22vh;
    --fall-duration: 10.117s;
    --fall-delay: 5.602s;
  }
  .star:nth-child(23) {
    --star-tail-length: 5.31em;
    --top-offset: 95.08vh;
    --fall-duration: 8.013s;
    --fall-delay: 2.376s;
  }
  .star:nth-child(24) {
    --star-tail-length: 7.07em;
    --top-offset: 90.15vh;
    --fall-duration: 10.822s;
    --fall-delay: 7.704s;
  }
  .star:nth-child(25) {
    --star-tail-length: 7.01em;
    --top-offset: 79.38vh;
    --fall-duration: 7.726s;
    --fall-delay: 0.552s;
  }
  .star:nth-child(26) {
    --star-tail-length: 6.63em;
    --top-offset: 94.32vh;
    --fall-duration: 11.423s;
    --fall-delay: 8.891s;
  }
  .star:nth-child(27) {
    --star-tail-length: 7.25em;
    --top-offset: 38.08vh;
    --fall-duration: 7.291s;
    --fall-delay: 0.163s;
  }
  .star:nth-child(28) {
    --star-tail-length: 5.07em;
    --top-offset: 53.68vh;
    --fall-duration: 6.729s;
    --fall-delay: 4.038s;
  }
  .star:nth-child(29) {
    --star-tail-length: 5.31em;
    --top-offset: 55.96vh;
    --fall-duration: 9.628s;
    --fall-delay: 7.538s;
  }
  .star:nth-child(30) {
    --star-tail-length: 6.13em;
    --top-offset: 27.86vh;
    --fall-duration: 7.648s;
    --fall-delay: 8.529s;
  }
  .star:nth-child(31) {
    --star-tail-length: 5.32em;
    --top-offset: 19.29vh;
    --fall-duration: 8.633s;
    --fall-delay: 9.168s;
  }
  .star:nth-child(32) {
    --star-tail-length: 6.63em;
    --top-offset: 58.31vh;
    --fall-duration: 9.696s;
    --fall-delay: 6.81s;
  }
  .star:nth-child(33) {
    --star-tail-length: 5.29em;
    --top-offset: 68.57vh;
    --fall-duration: 10.453s;
    --fall-delay: 9.881s;
  }
  .star:nth-child(34) {
    --star-tail-length: 7.04em;
    --top-offset: 0.17vh;
    --fall-duration: 11.541s;
    --fall-delay: 2.431s;
  }
  .star:nth-child(35) {
    --star-tail-length: 6.2em;
    --top-offset: 18.74vh;
    --fall-duration: 9.143s;
    --fall-delay: 4.252s;
  }
  .star:nth-child(36) {
    --star-tail-length: 6.58em;
    --top-offset: 59.97vh;
    --fall-duration: 9.337s;
    --fall-delay: 7.282s;
  }
  .star:nth-child(37) {
    --star-tail-length: 5.64em;
    --top-offset: 82.53vh;
    --fall-duration: 6.859s;
    --fall-delay: 6.725s;
  }
  .star:nth-child(38) {
    --star-tail-length: 6.68em;
    --top-offset: 14.53vh;
    --fall-duration: 8.795s;
    --fall-delay: 6.281s;
  }
  .star:nth-child(39) {
    --star-tail-length: 7.08em;
    --top-offset: 83.58vh;
    --fall-duration: 7.277s;
    --fall-delay: 7.218s;
  }
  .star:nth-child(40) {
    --star-tail-length: 5.65em;
    --top-offset: 39.46vh;
    --fall-duration: 7.716s;
    --fall-delay: 8.267s;
  }
  .star:nth-child(41) {
    --star-tail-length: 7.3em;
    --top-offset: 8vh;
    --fall-duration: 9.881s;
    --fall-delay: 2.07s;
  }
  .star:nth-child(42) {
    --star-tail-length: 5.49em;
    --top-offset: 18.6vh;
    --fall-duration: 11.945s;
    --fall-delay: 8.428s;
  }
  .star:nth-child(43) {
    --star-tail-length: 5.76em;
    --top-offset: 58.89vh;
    --fall-duration: 10.694s;
    --fall-delay: 8.994s;
  }
  .star:nth-child(44) {
    --star-tail-length: 6.22em;
    --top-offset: 17.72vh;
    --fall-duration: 10.731s;
    --fall-delay: 7.091s;
  }
  .star:nth-child(45) {
    --star-tail-length: 5.81em;
    --top-offset: 37.04vh;
    --fall-duration: 7.322s;
    --fall-delay: 3.429s;
  }
  .star:nth-child(46) {
    --star-tail-length: 5.36em;
    --top-offset: 40.29vh;
    --fall-duration: 11.943s;
    --fall-delay: 9.657s;
  }
  .star:nth-child(47) {
    --star-tail-length: 6.52em;
    --top-offset: 58.27vh;
    --fall-duration: 11.251s;
    --fall-delay: 6.761s;
  }
  .star:nth-child(48) {
    --star-tail-length: 7.22em;
    --top-offset: 70.75vh;
    --fall-duration: 11.024s;
    --fall-delay: 1.197s;
  }
  .star:nth-child(49) {
    --star-tail-length: 5.05em;
    --top-offset: 44.47vh;
    --fall-duration: 7.198s;
    --fall-delay: 6.396s;
  }
  .star:nth-child(50) {
    --star-tail-length: 5.96em;
    --top-offset: 25.95vh;
    --fall-duration: 7.696s;
    --fall-delay: 4.199s;
  }
  .star::before, .star::after {
    position: absolute;
    content: "";
    top: 0;
    left: calc(var(--star-width) / -2);
    width: var(--star-width);
    height: 100%;
    background: linear-gradient(45deg, transparent, currentColor, transparent);
    border-radius: inherit;
    animation: blink 2s linear infinite;
  }
  .star::before {
    transform: rotate(45deg);
  }
  .star::after {
    transform: rotate(-45deg);
  }
  
  @keyframes fall {
    to {
      transform: translate3d(-30em, 0, 0);
    }
  }
  @keyframes tail-fade {
    0%, 50% {
      width: var(--star-tail-length);
      opacity: 1;
    }
    70%, 80% {
      width: 0;
      opacity: 0.4;
    }
    100% {
      width: 0;
      opacity: 0;
    }
  }
  @keyframes blink {
    50% {
      opacity: 0.6;
    }
  }