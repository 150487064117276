h4 {
    text-align: left;

  }
  
  @media (max-width: 800px) {
    h4 {
      padding:10px;
      text-align: center;
    }
  }

  .carousel-item {
    width: 100%; /* Assure que le carrousel prend toute la largeur disponible */
    height: 350px; /* Hauteur fixe pour le conteneur */
    overflow: hidden; /* Cache les parties de l'image qui dépassent */
  }
  
  .carousel-img {
    width: 100%; /* L'image remplit la largeur du conteneur */
    height: 100%; /* L'image remplit la hauteur du conteneur */
    object-fit: cover; /* Couvre le conteneur sans déformation */
  }


  .loader {
    display: flex;
    justify-content: center; /* Centrer horizontalement */
    align-items: center;     /* Centrer verticalement */
    height: 30vh;           /* Remplir l'écran en hauteur */
    width: 100%;             /* Remplir l'écran en largeur */
    position: relative;      /* Pas de position fixe */
  }