.ContactWrapper {
    margin-top: 5rem;
  }
  
  .Image {
    max-width: 120px;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  
  .Technologies {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: -2rem;
  }
  
  .Tech {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100px;
    min-width: 100px;
    margin-top: 100px;
  }
  
  .TechImg {
    height: 40px;
    width: 40px;
    padding-bottom: 5px;
  }
  
  .TechName {
    font-size: 14px;
  }
  
  .SectionTitle {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 2rem;
    margin-top: 30px;
  }
  
  .SubTitle {
    text-align: center;
    margin-top: 40px;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  
  .SectionParagraph {
    margin-top: 10px;
    font-size: 18px;
    margin-bottom: 0.5rem;
    text-align: center;
  }
  
  .AboutBio {
    margin-bottom: 2rem;
  }
  