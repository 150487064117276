@import url(https://fonts.googleapis.com/css2?family=Anton&family=Bungee+Tint&family=New+Amsterdam&display=swap);

.logo {
}

.logo b {
  font: 500 4vw "Vibur"; /* Réduit la taille de la police en utilisant des unités relatives */
  font-family: "New Amsterdam", sans-serif;
  color: #fee;
  text-shadow: 0 -20px 50px, 0 0 2px, 0 0 0.5em #3498db, 0 0 0.5em #3498db, 0 0 0.1em #3498db, 0 5px 1px #000;
}

.logo b span {
  animation: blink linear infinite 2s;
}

.logo b span:nth-of-type(2) {
  animation: blink linear infinite 3s;
}


#btn-twtr {
  color: #fff;
  border: 2px solid;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: block;
  font-family: sans-serif;
  font-size: 14px;
  width: 13em;
  padding: 5px 10px;
  font-weight: 600;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0px 3px rgba(0, 0, 0, 0.2);
  opacity: 0.4;

  &:hover {
    color: #fff;
    opacity: 1;
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .logo b {
    font-size: 12vw; /* Ajuste la taille de la police pour les écrans plus petits */
  }

  #btn-twtr {
    font-size: 12px;
    width: 10em;
  }
}

@media (max-width: 480px) {
  .logo b {
    font-size: 15vw; /* Ajuste encore plus pour les très petits écrans */
  }

  #btn-twtr {
    font-size: 10px;
    width: 8em;
  }
}
