.project-video {
    margin-top: 150px;
    width: 80%;
    position: relative;
    height: 500px;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  
  .project-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
  
  .line-box {
    position: relative;
    margin-bottom: 20px;
  }
  
  .line-box::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 4px;
    background-color: white;
    border-radius: 3px;
  }
  
  .project-page {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("./FOND11.png");
    height: auto;
  }
  
  .project-section {
    background-color: black;
    padding: 40px;
    text-align: center;
    background-image: url("./FOND11.png");
    box-shadow: 0 0 10px rgba(255, 255, 255, 1);
  }
  
  .modal-content {
    position: absolute;
    background-color: transparent;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-body {
    width: 100%;
    height: 100%;
    background-color: transparent;
  }
  
  .modal-dialog {
    width: 80% !important;
    height: 60% !important;
    max-width: none !important;
    max-height: none !important;
  }
  
  .fullscreen-image {
    display: block;
    width: 100%;
    height: 100%;
  }
  
  .project-blocks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .project-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 20px;
    margin-top: 150px;
  }
  
  .project-image {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.1s;
    max-height: 800px;
    max-width: 800px;
  }
  
  .description {
    color: black;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: 8px;
    border: 2px solid black;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 24px;
  }
  
  .project-image:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
  
  h1 {
    font-size: 3vw;
    color: white;
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
  }
  
  @media (max-width: 700px) {
    .project-image {
      width:100%;
    }
    .description {
      width:100%;
      padding:10;
    }

    .project-section {
      width: 100%;
    }

    .project-video {
        width: 100%;
    }
  
    h2 {
      margin-top: 20px;
      color: white;
    }
  
    .description {
      font-size: 4vw;
    }
  
    h1 {
      font-size: 4vw;
    }
  
    .project-block {
      width:100%;
      margin-top: 90px;
    }
  }
  
  @media (max-width: 500px) {
    .project-block {
      width: 100%;
    }
    .project-video {
        width: 100%;
        height: 300px;

      }
    h1 {
      font-size: 6vw;
    }
  
    .project-section {
      width: 100%;
      padding: 20px;
    }

    .project-video {
      width: 100%;
    }
    
  }
  