@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

/* Base styles */
.ProjectWrapper {
  font-family: 'Montserrat', sans-serif;
  background-color: white;
  padding-bottom: 50px;
}



.category-banner {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  gap: 0px;
  border-radius: 15px;;
}

.category-button {
    padding: 12px 20px;
    background-color: #3a3a3a;
    color: #fff;
    border-radius: 25px;
    border: 2px solid #fff; /* Ajoute une bordure blanche */
    font-size: 1.1rem;
    font-weight: 600;
    transition: background-color 0.3s ease, transform 0.3s ease;
    cursor: pointer;
    margin: 0 20px; /* Ajoute un espacement horizontal entre les boutons */
  }

  
.category-button:hover {
  background-color: #007bff;
  transform: scale(1.05);
}

.category-button.active {
  background-color: #007bff;
  color: #fff;
  transform: scale(1.1);
}

.SectionTitle {
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 40px;
  color: #151418;
  text-transform: uppercase;
}

.category-banner {
    padding:30px;
    background-color: #3a3a3a;
}

.project-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  padding: 0 30px;
}

/* Project Card Style */
.project-card {
  background-color: #202020;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.project-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

@media (max-width: 1160px) {
  .category-banner {
    flex-direction: column;
    gap: 20px;
  }
}

/* Media Queries */
@media (max-width: 767px) {
  .category-banner {
    flex-direction: column;
    gap: 40px;
  }

  .category-button {
    font-size: 0.9rem;
    padding: 10px 15px;
  }

  .SectionTitle {
    font-size: 1.5rem;
  }

  .project-cards {
    flex-direction: column;
    padding: 0 10px;
  }

  .project-card {
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .category-button {
    font-size: 1rem;
    padding: 10px 20px;
  }

  .SectionTitle {
    font-size: 1.8rem;
  }

  .project-card {
    width: 45%;
  }
}

@media (min-width: 1024px) {
  .category-button {
    font-size: 1.1rem;
    padding: 12px 25px;
  }

  .SectionTitle {
    font-size: 2rem;
  }

  .project-card {
    width: 30%;
  }
}
